import { useHistory, useParams } from "react-router-dom";
import "survey-react/survey.css";
import styles from "./Reports.module.css";
import { cx } from "utilities/cx";
import { useToastr } from "components/utils/toastr/ToastrController";
import { getAnyErrorKey } from "utilities/getAnyErrorKey";
import { useReport } from "api/reports/hooks";
import { reportKeys } from "api/reports/keys";
import { Report } from "api/reports/models";
import bacteriaImg from "assets/images/bakterid_ikoonid.svg";
import markerImg from "../../assets/images/marker.svg";
import marker2Img from "../../assets/images/marker2.svg";
import m1 from "../../assets/images/m1.png";
import m2 from "../../assets/images/m2.png";
import m3 from "../../assets/images/m3.png";
import m4 from "../../assets/images/m4.png";
import m5 from "../../assets/images/m5.png";
//import chart from "../../assets/images/chart.png";
import chart from "../../assets/images/version1/enterotype_plot.svg";
import obesityPreventWave from "../../assets/images/version1/obesityminusWave.svg";
import obesityFavorableWave from "../../assets/images/version1/obesityWave.svg";

import { assertIsDefined } from "utilities/assertIsDefined";
import { PrintPdfButton } from "./ReportPdf";
import { useQuery } from "components/utils/queryProvider/useQuery";
import {
  indexToColorDict,
  page4IconsDict,
  page4MainIcons,
  recommendationIconDict,
  printNumberReport,
} from "./constants";

import { Pie } from 'react-chartjs-2';
import Chart from "react-apexcharts";
import { htmlRender } from "utilities/htmlRender";

import { PrintSimplePdfButton } from "./SimpleReportPdf";

const BG_PLOT_WIDTH = 95;
const LEFT_BG_MARGIN = 2;
const PUT_STAR_WHEN_OVER = 99.9;

export const Reports = () => {
  const { query, updateQuery } = useQuery();
  const { code } = useParams<{ code: string }>();
  const history = useHistory();
  const toastr = useToastr();

  const { data: report, isLoading: inProgress } = useReport(reportKeys.report(code), String(code), {
    onError: (err) => {
      toastr.neutral({
        title: "",
        text: getAnyErrorKey(err),
      });
      history.goBack();
    },
  });

  if (inProgress) {
    return <div>Ładowanie raportu</div>;
  }

  if (!report) {
    return <div>Brak raportu</div>;
  }

  const currentPageData = (() => {
    const reportPage = report.data.find((el) => el.page === Number(query.page || 1));
    assertIsDefined(reportPage);

    function compare(
      a: Report["data"][number]["sections"][number],
      b: Report["data"][number]["sections"][number],
    ) {
      if (a.order < b.order) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }
      return 0;
    }

    const sortedReportPage = { ...reportPage, sections: reportPage.sections.sort(compare) };
    return sortedReportPage;
  })();

  const pollDisplay = (pollResultId: String, pollId: String) => {
      return (<div className=" d-flex flex-column align-items-center justify-content-center mt-4 pt-2 ">
        <button
          className="btnPrimary px-2 mt-2"
          onClick={() => history.push(`/poll/${pollResultId}/${pollId}`)}
        >
        Obejrzyj ankietę
        </button>
      </div>)
  }

  return (
    <div className="d-flex align-items-start justify-content-center">
      <div className="mainContainer">
        <div className="row mt-5">
          <div className="d-flex align-items-end justify-content-end">
            <div className="pb-1 cursor-pointer" onClick={() => history.push("/")}>
              {"<"} Powrót do pulpitu
            </div>
          </div>
          <div className="col-12 col-md-3 mb-4 pe-md-0">
            <div className={cx("d-flex flex-column justify-content-between p-4", styles.navBox)}>
              <h2 className={cx(styles.pb5, styles.mb5)}>Raport</h2>
              <nav className="mb-4">
                {report.data.map(({ page, title }) => (
                  <div
                    className={cx(styles.navLink, {
                      [styles.activeLink]: page === Number(query.page || 1),
                    })}
                    onClick={() => updateQuery({ page })}
                  >
                    {title}
                  </div>
                ))}
              </nav>
              <div className="d-flex flex-column align-items-start mt-4 pt-2">
                {(!report.isSimple) && (<button
                  className="btnSecondary px-2 mb-2"
                  onClick={() => history.push(`/krona/${code}`)}
                >
                  Interaktywna przeglądarka mikrobiomu
                </button>)}

                {(report.isSimple) && (<PrintSimplePdfButton report={report} />)}
                {/* {report.isSimple && (<PrintSimplePdfButton report={report} /> )} */}
                {(!report.isSimple) && (<PrintPdfButton report={report} />)}

                {(!report.isSimple) && report.displayPoll && report.pollId && report.pollResultId && pollDisplay(report.pollResultId, report.pollId)}
                {(!report.isSimple && report.hasTable) && (<button
                  className="btnSecondary px-2"
                  onClick={() => history.push(`/table/${code}`)}
                >
                  Tabela składu mikrobiomu
                </button>)}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-9 ps-0">
            <div className={cx("px-4", styles.reportBox)}>
              <div className="row">
                <div className="col-12">
                  <h5 className="mb-3">{currentPageData.title}</h5>
                </div>
              </div>
              <div className="content mb-4">
                {currentPageData.sections.map((section) => {
                  if (section.type === "paragraph") {
                    return (
                      <div className="my-3">
                        <div className={cx(styles.textBox, styles.textBoxLg, "row")}>
                          {section.title && (
                            <div className="row">
                              <div className="col-12">
                                <h6 className="mb-3">{section.title}</h6>
                                <div>{htmlRender(section.value)}</div>
                              </div>
                            </div>
                          )}
                          <div
                            className={
                              section.diagram?.type === "dominant_types" ? "col-12 col-xl-6" : "col-12"
                            }
                          >
                            {section.description.map((el) => (
                              section.title === "TWÓJ NANOBIOTYP" ? 
                                <div className={cx(styles.nanobiotype)}>{htmlRender(el)}</div> : 
                                <div className="my-3" >{htmlRender(el)}</div>
                            ))}
                            {Boolean('plotdescription' in section) && ( section.plotdescription ) && (
                                    section.plotdescription.map((el) => (
                                      <div>
                                        <div className={styles.my2} />
                                        <div
                                          className={cx(styles.description, styles.my1, styles.descriptionframe)}
                                          >{htmlRender(el )}</div>
                                      </div>
                            )))}
                          </div>

                        {(Number(query.page || 1) === 1 && section.order === 0) && (
                          <div className={cx("col-12 my-2", styles.infographic)}>
                            <img src={bacteriaImg} alt="grafika" />
                          </div>
                        )}
                          {section.diagram && section.diagram.type === "dominant_types" && (
                            <>
                              <div className="col-12 col-xl-6">
                                <div className="col-11">
                                  <div className={styles.pieChartContainer}>
                                    <div
                                      className={styles.pieChart}
                                      style={{
                                        background: getDiagramGradient(section.diagram.values),
                                      }}
                                    ></div>

                                    <div className={styles.legenda}>
                                      {section.diagram.values.map(({ value, name }, index) => (
                                        <div className={styles.entry}>
                                          <div
                                            className={cx(styles.entryColor)}
                                            style={{ background: indexToColorDict[index] }}
                                          ></div>
                                          <div className={styles.entryText}>
                                            {name} {value >= 0.05 ? `${value.toFixed(2)}%` : "< 0.05%"}
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          {section.diagram?.type === "dominant_kinds" && (
                            <div className="col-12">
                              <div className={styles.stackedBarChartBox}>
                                <div className={styles.stackedBarChart}>
                                  <div className={styles.stackedBar}>
                                    {section.diagram.values.map(({ value, name }, index) => (
                                      <span className={styles.value} style={{ width: `${value}%` }}>
                                        {value > 5 ? `${value.toFixed(2)}%` : ""}
                                      </span>
                                    ))}
                                  </div>
                                </div>
                                <div className={styles.stackedBarLegend}>
                                  {section.diagram.values.map(({ value, name }, index) => (
                                    <div>
                                      <span className={styles.value}></span>
                                      <span className={styles.label}>
                                        {name}: {value.toFixed(2)}%
                                      </span>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )}

                          {section.diagram?.type === "dominance5" && (
                              <div>
                                <div className="d-flex align-items-center">
                                  <div className={styles.groupTitle}>
                                    {section.diagram.title}
                                  </div>
                                  
                                  <div className={styles.speciesdiv}>
                                    <div className={styles.species}>
                                      <strong>{section.diagram.values.toFixed(1)}%</strong>
                                    </div>
                                    <div className={styles.dominanceContainer}>
                                      <Pie data={{
                                        labels: ['5 najczęstszych\n gatunków', 'pozostałe'],
                                        datasets: [
                                          {
                                            data: [section.diagram.values.toFixed(1), (100-section.diagram.values).toFixed(1)],
                                            backgroundColor: [
                                              '#b1235c', '#999'
                                            ],
                                            borderWidth: 1,
                                          }
                                        ]
                                        }}
                                        options={{ 
                                          plugins: {  
                                            legend: { display: true, position: "right" , fullSize: false,
                                            maxWidth: 350, 
                                              labels: { font: {  size: 14, family: "Rubik" } },
                                            },

                                          }, 
                                          aspectRatio: 4,
                                          events: ['mousemove'],
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>)}
                          {section.diagram?.type === "number_of_species" && (
                              <div className="align-items-center">
                                <div className="d-flex align-items-center">
                                  
                                  <div className={styles.groupTitle}>
                                    {section.diagram.title}
                                  </div>
                            
                                  <div className={styles.speciesdiv}>
                                    <div className={styles.species}>
                                      <strong>{section.diagram.values}</strong>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          )} 
                          {section.diagram?.type === "number_of_species_range" && (
                            <div className="align-items-center">
                              <div className="d-block ">
                                <div className={styles.tle}>
                                  {section.diagram.title}
                                </div>
                                <div className={styles.speciesdiv}>
                                  <div className={styles.species}>
                                    <strong>{section.diagram.result}</strong>
                                  </div>
                                  <div className={styles.speciesdesc}>
                                    Zakres wartości obserwowany w bazie odniesienia: <br/>
                                    {section.diagram.referenceMin} -  {section.diagram.referenceMax}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )} 
                        </div>
                      </div>
                    );
                  }

                  if (section.type === "recommendation") {
                    return (
                      <div className="mt-4" style={{ padding: "0 0.75rem" }}>
                        <div className={cx(styles.textBox)} style={{ paddingTop: "0" }}>
                          <div className="row">
                            <div style={{ width: "60px" }}>
                              <img src={recommendationIconDict[section.diagram.mainIcon]} alt="" />
                            </div>
                            <div style={{ maxWidth: "calc(100% - 60px)" }}>
                              {section.description.map((el) => (
                                <div className="my-1" >{htmlRender(el)}</div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }

                  if (section.type === "poll_result") {
                    return (
                      <div className="col-12" style={{ padding: "0 0.75rem" }}>
                        <div className="mt-5">
                          <div className="d-flex align-items-center">
                            <div>
                              <h6 className="me-3 my-0">{section.title}</h6>
                            </div>
                            {section.diagram.type === "poll_result_bmi" && (
                              <div className={styles.resultbmi}>
                                {section.diagram.values[1].toFixed(1)} kg/m<sup>2</sup>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="mb-4">
                          <div className={cx(styles.textBox)}>
                            {section.diagram.type !== "poll_result_summary" && (
                            <div className="row">
                                <div className="mb-2">
                                  <div className={styles.scaleChartBox}>
                                    {section.diagram.type === "poll_result" && (<div className={styles.scaleChartBar}>
                                      <span className={styles.divider}></span>
                                      <span className={styles.divider}></span>
                                    </div>)}
                                    {section.diagram.type === "poll_result_exercises" && (<div
                                      className={cx(styles.scaleChartBar, styles.gradientActivity)}
                                    ></div>)}
                                    {section.diagram.type === "poll_result_alcohol" && (<div
                                      className={cx(styles.scaleChartBar, styles.gradientAlcohol)}
                                    ></div>)}
                                    {section.diagram.type === "poll_result_unhealthy" && (<div
                                      className={cx(styles.scaleChartBar, styles.gradientUnhealthy)}
                                    ></div>)}
                                    {section.diagram.type === "poll_result_redgreen" && (<div
                                      className={cx(styles.scaleChartBar, styles.gradientRedGreen)}
                                    >
                                      <span className={styles.divider}></span>
                                      <span className={styles.divider}></span>
                                    </div>)}
                                    {section.diagram.type === "poll_result_bmi" && (<div
                                      className={cx(styles.scaleChartBar, styles.gradientBMI)}
                                    ></div>)}
                                    <img
                                      className={styles.scaleChartMarker}
                                      style={{
                                        left: `${
                                          section.diagram.values[0] > 98 ? 98 : section.diagram.values[0] 
                                        }%`,
                                      }}                                      
                                      src={markerImg}
                                      alt=""
                                    />
                                    <div className="d-flex align-items-center justify-content-between">
                                      <div className={styles.scaleChartLabel}>
                                        {section.diagram.leftBandText}
                                      </div>
                                      {section.diagram?.center && (<div className={styles.scaleChartLabel}>
                                        {section.diagram.center}
                                      </div>)}
                                      <div className={styles.scaleChartLabel}>
                                        {section.diagram.rightBandText}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              
                              <div className="col-12">
                                {section.description.map((el) => (                                
                                  <div className="my-3" >{htmlRender(el)}</div>
                                ))}
                              </div>
                            </div>)} 
                            
                            {section.diagram.type === "poll_result_summary" && (
                              <div className="row">
                                <div className="col-12">
                                  {section.description.map((el) => (
                                    <div className="my-3" >{htmlRender(el)}</div>
                                  ))}
                                </div>

                              <div className="col-12 pe-0">
                                  <div className={styles.RadarChartArea}>
                                  <div className={styles.RadarChartBox}>
                                    <Chart  type='radar' width='600px'
                                        series={[{name: "Twój wynik", data: Object.values(section.diagram.pollvalues), color: '#3c396b'}]}
                                        options={{
                                          chart: {
                                                type: 'radar',
                                                toolbar: {show: false},
                                                background: '#f3f3f3'
                                          },
                                          plotOptions: {
                                                radar: {
                                                size: 160,
                                                polygons: {
                                                  fill: {
                                                    colors: ['#009688', '#99c95a', '#ffeb3b', '#fa9738', '#f44336']},
                                                  }
                                                },
                                          },
                                          markers: { size: 5 },
                                          dataLabels: {  enabled: false  },
                                          xaxis: {
                                            categories: Object.values(section.diagram.captions),
                                            labels: {
                                              style: { fontSize: '15px',
                                              fontFamily: 'Rubik',
                                              colors: ['#555555', '#555555', '#555555', '#555555', '#555555', '#555555', '#555555', '#555555', '#555555', '#555555', '#555555', ]
                                            }}
                                          },
                                          yaxis: {min: -5, max: 100, tickAmount: 5, show: false, labels: {show: false}}
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  }

                  if (section.type === "lab_result") {
                    return (
                      <div className="mt-4 mb-5">
                        <div className={cx(styles.textBox)}>
                          <div className="row">
                            <div className="col-12">
                              <div className="d-flex align-items-center">
                                <div className="d-flex align-items-center justify-content-between">
                                  <img src={page4MainIcons[section.diagram.mainIcon].icon} alt="" />
                                  <div className={cx(styles.groupTitle, "me-2")}>
                                    {section.title}
                                  </div>
                                </div>
                                {section.diagram.type === "lab_result" && (
                                  <div className={styles.result}>
                                    <strong>{section.diagram.values.perc.toFixed(2)}</strong> %
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-12 my-1">
                              {section.diagram.type === "lab_result" && (
                                <div className="mb-2">
                                  <div className={styles.barChartBox}>
                                    <div className={styles.barChartBar}>
                                      <img
                                        className={styles.wave}
                                        src={page4MainIcons[section.diagram.mainIcon].diagram}
                                        alt=""
                                      />
                                    </div>
                                    {(section.diagram.values.perc * 100) /
                                      section.diagram.maxValue >
                                      
                                      PUT_STAR_WHEN_OVER && <div className={styles.star}>*</div>}
                                    <img
                                      className={styles.barChartMarker}
                                      style={{
                                        left: `${
                                          (section.diagram.values.perc * 100) /
                                            section.diagram.maxValue >
                                        PUT_STAR_WHEN_OVER
                                            ? BG_PLOT_WIDTH + LEFT_BG_MARGIN
                                            : (section.diagram.values.perc * BG_PLOT_WIDTH) /
                                              section.diagram.maxValue + LEFT_BG_MARGIN
                                        }%`,
                                        bottom: "8px",
                                      }}
                                      src={marker2Img}
                                      alt=""
                                    />

                                    <div
                                      className={cx(
                                        styles.barChartLabelsBox,
                                        "d-flex justify-content-between",
                                      )}
                                    >
                                      <div
                                        className={cx(
                                          styles.barChartLabel,
                                          styles.barChartLabelLeft,
                                        )}
                                      >
                                        {section.diagram.minValue.toFixed(2)}%
                                      </div>
                                      <div
                                        className={cx(
                                          styles.barChartLabel,
                                          styles.barChartLabelRight,
                                        )}
                                      >
                                        {section.diagram.maxValue.toFixed(2)}%
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {section.diagram.type === "double_lab_result" && (
                                <div className="mb-2">
                                  {section.diagram.type === "double_lab_result" && (
                                    <div className="d-flex align-items-center justify-content-center">
                                      <div className={cx(styles.resultLabel, "me-2")}>
                                        {/* Pozytywny wpływ */}Przeciwdziałające otyłości
                                      </div>
                                      <div className={styles.result}>
                                        <strong>{section.diagram.plus.perc.toFixed(2)}</strong> %
                                      </div>
                                    </div>
                                  )}
                                  <div className={styles.barChartBox}>
                                    <div className={styles.barChartBar}>
                                      <img
                                        className={styles.wave}
                                        src={obesityPreventWave}
                                        alt=""
                                      />
                                    </div>
                                    {(section.diagram.plus.perc * 100) /
                                      section.diagram.maxValuePlus >
                                      PUT_STAR_WHEN_OVER && <div className={styles.star}>*</div>}
                                    <img
                                      className={styles.barChartMarker}
                                      style={{
                                        left: `${
                                          (section.diagram.plus.perc * 100) /
                                            section.diagram.maxValuePlus >
                                          
                                        PUT_STAR_WHEN_OVER
                                          ? BG_PLOT_WIDTH + LEFT_BG_MARGIN
                                          : (section.diagram.plus.perc * BG_PLOT_WIDTH) /
                                            section.diagram.maxValuePlus + LEFT_BG_MARGIN
                                        }%`,
                                        bottom: "8px",
                                      }}
                                      src={marker2Img}
                                      alt=""
                                    />

                                    <div
                                      className={cx(
                                        styles.barChartLabelsBox,
                                        "d-flex justify-content-between",
                                      )}
                                    >
                                      <div
                                        className={cx(
                                          styles.barChartLabel,
                                          styles.barChartLabelLeft,
                                        )}
                                      >
                                        {section.diagram.minValuePlus.toFixed(2)}%
                                      </div>
                                      <div
                                        className={cx(
                                          styles.barChartLabel,
                                          styles.barChartLabelRight,
                                        )}
                                      >
                                        {section.diagram.maxValuePlus.toFixed(2)}%
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {section.diagram.type === "double_lab_result" && (
                                <div>
                                  <div className="d-flex align-items-center justify-content-center">
                                    <div className={cx(styles.resultLabel, "me-2")}>
                                      {/* Negatywny wpływ */}Sprzyjające otyłości
                                    </div>

                                    <div className={styles.result}>
                                      <strong>{section.diagram.minus.perc.toFixed(2)}</strong> %
                                    </div>
                                  </div>
                                  <div className="mb-2">
                                    <div className={styles.barChartBox}>
                                      <div className={styles.barChartBar}>
                                        <img
                                          className={styles.wave}
                                          src={obesityFavorableWave}
                                          alt=""
                                        />
                                      </div>
                                      {(section.diagram.minus.perc * 100) /
                                        section.diagram.maxValueMinus >
                                        PUT_STAR_WHEN_OVER && <div className={styles.star}>*</div>}
                                      <img
                                        className={styles.barChartMarker}
                                        style={{
                                          left: `${
                                            (section.diagram.minus.perc * 100) /
                                              section.diagram.maxValueMinus >
                                        PUT_STAR_WHEN_OVER
                                              ? BG_PLOT_WIDTH + LEFT_BG_MARGIN
                                              : (section.diagram.minus.perc * BG_PLOT_WIDTH) /
                                                section.diagram.maxValueMinus + LEFT_BG_MARGIN
                                          }%`,
                                          bottom: "8px",
                                        }}
                                        src={marker2Img}
                                        alt=""
                                      />

                                      <div
                                        className={cx(
                                          styles.barChartLabelsBox,
                                          "d-flex justify-content-between",
                                        )}
                                      >
                                        <div
                                          className={cx(
                                            styles.barChartLabel,
                                            styles.barChartLabelLeft,
                                          )}
                                        >
                                          {section.diagram.minValueMinus.toFixed(2)}%
                                        </div>
                                        <div
                                          className={cx(
                                            styles.barChartLabel,
                                            styles.barChartLabelRight,
                                          )}
                                        >
                                          {section.diagram.maxValueMinus.toFixed(2)}%
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="col-12">
                              {section.description.map((el) => (
                                <div
                                  className={cx("my-3")}
                                  style={{ padding: "0 0.75rem" }}
                                >
                                {htmlRender(el)}</div>
                              ))}
                              <div
                                className="d-flex align-items-start"
                                style={{ padding: "0 0.75rem" }}
                              >
                                {section.diagram.icons.map((icon) => (
                                  <div className="d-flex flex-column align-items-center mr-1">
                                    <div>
                                      <img
                                        src={page4IconsDict[icon] ? page4IconsDict[icon].icon : ""}
                                        alt=""
                                      />
                                    </div>
                                    <div className={styles.iconLabel}>
                                      {page4IconsDict[icon]
                                        ? page4IconsDict[icon].label.toUpperCase()
                                        : ""}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                  if (section.type === "info") {
                    return (
                      <div className="my-4" style={{ padding: "0 0.75rem" }}>
                        <div className="d-flex align-items-center justify-content-between mb-3">
                          <h6 className="mb-0 mt-4">{section.title}</h6>
                        </div>
                        <div className="my-3" >{htmlRender(section.value)}</div>
                        <div className="row">
                          <div className="col-12 col-xl-6">
                            {Boolean(section.description.length == 0 )}   <div className="my-3" />
                            {section.description.map((el) => (
                              <div className="my-3" >{htmlRender(el)}</div>
                            ))}
                          </div>
                          {section.diagram?.type === "enterotype" && (
                            <div className="col-6 pe-0">
                              <div className="col-11">
                                <div className={styles.cloudsChartBox}>
                                  <div className={styles.cloudsChart}>
                                    <img src={chart} alt="" />
                                  </div>
                                  {(() => {
                                    if (section.diagram?.type === "enterotype") {
                                      const bounds = section.diagram.bounds;
                                      const xValue = section.diagram.values.x;
                                      const yValue = section.diagram.values.y;
                                      const isoutlier = Boolean('outlier' in section.diagram) && Boolean(section.diagram.outlier === "True");
                                      //console.log("xval="+xValue+"   bounds.xRange.start="+bounds.xRange.start+" bounds.xRange.end=" + bounds.xRange.end);
                                      //console.log("xval="+yValue+"   bounds.yRange.start="+bounds.yRange.start+" bounds.yRange.end=" + bounds.yRange.end);
                                      return (
                                        <div
                                          className={styles.cloudsChartMarker}
                                          style={{
                                            bottom: `${
                                              ((yValue - bounds.yRange.start) * 100) /
                                              (bounds.yRange.end - bounds.yRange.start)
                                            }%`,
                                            left: `${
                                              ((xValue - bounds.xRange.start) * 100) /
                                              (bounds.xRange.end - bounds.xRange.start)
                                            }%`,
                                          }}
                                        >
                                          <img src={markerImg} alt=""  />
                                          {Boolean(isoutlier) && <div className={styles.cloudStar}>*</div>}
                                        </div>
                                      );
                                    } else {
                                      return null;
                                    }
                                  })()}
                                </div>
                                <div className={cx("", styles.cloudsChartLegend)}>
                                  <div className={cx(styles.legendInfo, "me-1 mb-2")}>
                                    <img src={m2} alt="" />
                                    <span className={cx(styles.label, "ps-2")}>Prevotella</span>
                                  </div>
                                  <div className={cx(styles.legendInfo, "me-1 mb-2")}>
                                    <img src={m1} alt="" />
                                    <span className={cx(styles.label, "ps-2")}>
                                      Prevotella + Firmicutes
                                    </span>
                                  </div>
                                  <div className={cx(styles.legendInfo, "me-1 mb-2")}>
                                    <img src={m5} alt="" />
                                    <span className={cx(styles.label, "ps-2")}>
                                      Firmicutes + Ruminococcus
                                    </span>
                                  </div>
                                  <div className={cx(styles.legendInfo, "me-1 mb-2")}>
                                    <img src={m4} alt="" />
                                    <span className={cx(styles.label, "ps-2")}>
                                     Firmicutes + Bacteroides
                                    </span>
                                  </div>
                                  <div className={cx(styles.legendInfo, "me-1 mb-2")}>
                                    <img src={m3} alt="" />
                                    <span className={cx(styles.label, "ps-2")}>Bacteroides</span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="d-flex col-1"
                                style={{
                                  position: "relative",
                                  marginTop: "-142px",
                                  minHeight: "142px",
                                }}
                              >
                                {Boolean('hint' in section) && (
                                  <div className={styles.hintBox}>
                                    <div className={styles.hint}>
                                      <em>i</em>
                                    </div>
                                    <div
                                      className={styles.hintTooltip}
                                      style={{
                                        top: "initial",
                                        right: "initial",
                                        bottom: "145px",
                                        left: "0",
                                      }}
                                    >
                                      <div
                                        className={cx("my-3")}>
                                        {section.hint}
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {Boolean(section.description.length > 1) && !Boolean('hint' in section) && (
                                  <div className={styles.hintBox}>
                                    <div className={styles.hint}>
                                      <em>i</em>
                                    </div>
                                    <div className={styles.hintTooltip}
                                      style={{
                                        top: "initial",
                                        right: "initial",
                                        bottom: "145px",
                                        left: "0",
                                      }}
                                    >
                                      <div className={cx("my-3")}> 
                                        {htmlRender(section.description.length === 2
                                          ? section.description[1]
                                          : section.description[0])}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}

                          {section.diagram && section.diagram.type === "diversity_index" && (
                            <div className="col-12 col-xl-6 pe-0">
                              <div className="col-11">
                                <div className={styles.chartArea}>
                                  <div className={styles.halfDonutChartBox}>
                                    <div className={styles.halfDonutChart}>
                                      <div
                                        style={{
                                          transform: `rotate(${
                                            180 * Number(section.diagram.values[0]) - 2
                                          }deg)`,
                                        }}
                                      >
                                        <span></span>
                                      </div>
                                      <div
                                        style={{
                                          transform: `rotate(${
                                            180 * Number(section.diagram.values[0]) -
                                            2 +
                                            2
                                          }deg)`,
                                        }}
                                      >
                                        <span
                                          style={{
                                            transform: `rotate(${
                                              180 * Number(section.diagram.values[0]) > 90 ? 180 : -5
                                            }deg)`,
                                            marginTop: "0px",
                                          }}
                                        >
                                          {section.diagram.values[0].toFixed(2)}
                                        </span>
                                      </div>
                                      <div
                                        style={{
                                          transform: `rotate(${
                                            180 * section.diagram.values[2] - 2
                                          }deg)`,
                                        }}
                                      >
                                        <span></span>
                                      </div>
                                      <div
                                        style={{
                                          transform: `rotate(${
                                            180 * section.diagram.values[2] - 2 + 2
                                          }deg)`,
                                        }}
                                      >
                                        <span 
                                          style={{
                                            transform: `rotate(${
                                              180 * Number(section.diagram.values[0]) > 90 ? 180 : -5
                                            }deg)`,
                                            marginTop: "0px",
                                          }}>{section.diagram.values[2].toFixed(2)}</span>
                                      </div>
                                      <div
                                        style={{
                                          transform: "rotate(180deg)",
                                        }}
                                      >
                                        <span></span>
                                      </div>
                                      <div
                                        className={styles.marker}
                                        style={{
                                          transform: `rotate(${
                                            180 * section.diagram.values[1] 
                                          }deg)`,
                                        }}
                                      >
                                      <img
                                        className={styles.donutmarkerImg}                              
                                        src={markerImg}
                                        alt=""
                                      />
                                      </div>
                                    </div>
                                  </div>
                                  <div className={styles.halfDonutChartLegend}>
                                    <span>Twój wynik to:</span>
                                    <strong>{section.diagram.values[1].toFixed(2)}</strong>
                                  </div>
                                </div>

                                <div
                                  className={cx(
                                    styles.chartLabelsContainer,
                                    "d-flex justify-content-between pt-2",
                                  )}
                                >
                                  <div className={styles.chartLabel} style={{ paddingLeft: "0px" }}>
                                    <div>0</div>
                                  </div>
                                  <div
                                    className={styles.chartLabel}
                                    style={{ paddingRight: "0px" }}
                                  >
                                    <div> 1</div>
                                  </div>
                                </div>
                              </div>
                              {Boolean('hint' in section) && ( <div
                                className="d-flex col-1"
                                style={{ position: "relative", marginTop: "-63px" }}
                              >
                                  <div className={styles.hintBox}>
                                    <div className={styles.hint}>
                                      <em>i</em>
                                    </div>
                                    <div className={styles.hintTooltip}
                                      style={{
                                        top: "initial",
                                        right: "initial",
                                        bottom: "40px",
                                        left: "0",
                                      }}>
                                      <div className={cx("my-3")} >
                                        {htmlRender(section.hint)}
                                      </div>
                                    </div>
                                  </div>

                                {Boolean(section.description.length > 1) && !Boolean('hint' in section) && (
                                  <div className={styles.hintBox}>
                                    <div className={styles.hint}>
                                      <em>i</em>
                                    </div>
                                    <div className={styles.hintTooltip}
                                      style={{
                                        top: "initial",
                                        right: "initial",
                                        bottom: "40px",
                                        left: "0",
                                      }}>
                                      <div className={cx("my-3")}> 
                                        {htmlRender(
                                            section.description.length === 2
                                            ? section.description[1]
                                            : section.description[0])}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                                )}
                            </div>
                          )}

                          {section.diagram && (section.diagram.type === "halfdonut" || section.diagram.type === "halfdonut_perc") && (
                            <div className="col-12 col-xl-6 pe-0">
                              <div className="col-11">
                                <div className={styles.chartArea}>
                                  <div className={styles.halfDonutChartBox}>
                                    <div className={styles.halfDonutChart}>
                                      <div
                                        style={{
                                          transform: `rotate(${
                                            180 * Number(section.diagram.referenceMin)/Number(section.diagram.plotMax - section.diagram.plotMin) - 2
                                          }deg)`,
                                        }}
                                      > <span></span>
                                      </div>
                                      <div
                                        style={{
                                          transform: `rotate(${
                                            180 * Number(section.diagram.referenceMin)/Number(section.diagram.plotMax - section.diagram.plotMin) 
                                          }deg)`,
                                        }}
                                      > <span
                                          style={{
                                            transform: `rotate(${
                                              180 * Number(section.diagram.referenceMin)/Number(section.diagram.plotMax - section.diagram.plotMin) > 90 ? -175 : -5
                                            }deg)`,
                                            marginTop: "0px",
                                          }}
                                        >
                                          {printNumberReport(section.diagram.referenceMin, section.diagram.type === "halfdonut_perc" )}
                                        </span>
                                      </div>
                                      <div
                                        style={{
                                          transform: `rotate(${
                                            180 * section.diagram.referenceMax/Number(section.diagram.plotMax - section.diagram.plotMin) - 2
                                          }deg)`,
                                        }}
                                      > <span></span>
                                      </div>
                                      <div
                                        style={{
                                          transform: `rotate(${
                                            180 * section.diagram.referenceMax/Number(section.diagram.plotMax - section.diagram.plotMin)
                                          }deg)`,
                                        }}
                                      > <span 
                                          style={{
                                            transform: `rotate(${
                                              180 * Number(section.diagram.referenceMax)/Number(section.diagram.plotMax - section.diagram.plotMin) > 90 ? -175 : -5
                                            }deg)`,
                                            marginTop: "0px",
                                          }}> 
                                          {printNumberReport(section.diagram.referenceMax, section.diagram.type === "halfdonut_perc" )}</span>
                                      </div>
                                      <div style={{
                                          transform: "rotate(180deg)",
                                        }}><span></span></div>
                                      <div className={styles.marker}
                                        style={{
                                          transform: `rotate(${
                                            180 * section.diagram.result/Number(section.diagram.plotMax - section.diagram.plotMin)
                                          }deg)`,
                                        }}
                                      >
                                        <img className={styles.donutmarkerImg} src={markerImg} alt=""/>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={styles.halfDonutChartLegend}>
                                    <span>Twój wynik to:</span>
                                    <strong>    
                                      {printNumberReport(section.diagram.result, section.diagram.type === "halfdonut_perc" )}</strong>
                                  </div>
                                </div>
                                <div className={cx(styles.chartLabelsContainer,styles.pt2,
                                    "d-flex justify-content-between pt-2",
                                  )}
                                >
                                  <div className={styles.chartLabel} style={{ paddingLeft: "5px" }}>
                                    <div>{printNumberReport(section.diagram.plotMin, section.diagram.type === "halfdonut_perc" )}</div>
                                  </div>
                                  <div
                                    className={styles.chartLabel}
                                    style={{ paddingRight: "0px" }}
                                  >
                                    <div>{printNumberReport(section.diagram.plotMax, section.diagram.type === "halfdonut_perc" )}</div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="d-flex col-1"
                                style={{ position: "relative", marginTop: "-63px" }}
                              >
                                {Boolean('hint' in section) && (
                                  <div className={styles.hintBox}>
                                    <div className={styles.hint}>
                                      <em>i</em>
                                    </div>
                                    <div className={styles.hintTooltip}
                                      style={{
                                        top: "initial",
                                        right: "initial",
                                        bottom: "40px",
                                        left: "0",
                                      }}>
                                      <div className={cx("my-3")} >
                                        {htmlRender(section.hint)}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  }
                  return <div></div>;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function getDiagramGradient(values: { name: string; value: number }[]) {
  const reducedValues = values.reduce(
    (
      acc: { name: string; value: number }[],
      el: { name: string; value: number },
      index: number,
    ) => {
      if (index === 0) {
        acc.push(el);
      } else {
        acc.push({ name: el.name, value: acc[index - 1].value + el.value });
      }

      return acc;
    },
    [],
  );

  const gradientValue = reducedValues.map(
    (el, index) =>
      `${index !== 0 ? " " : ""}${indexToColorDict[index]} ${
        values[index - 1] ? values[index - 1].value : 0
      }% ${el.value}%`,
  );

  return `conic-gradient(${gradientValue} `;
}
